define(['app', '$window', 'accessibilityAnnouncer'], (app, $window, accessibilityAnnouncer) => {

  const responsiveAccountHeader = () => {
    const component = {};

    const _config = {
      selectors: {
        accountHeaderButton: '.responsiveAccountHeader_openAccountButton',
        accountHeaderButtonMobile: '.responsiveAccountHeader_openAccountButtonMobile',
        basketHeaderButton: '#responsiveFlyoutBasket_openBasketButtonMobile',
        accountOverlay: '.responsiveAccountHeader_overlay',
        logoutButton: '.responsiveAccountHeader_signOut',
        openFlyoutPanel: '[data-js-element=responsiveAccountHeader_openAccountPanel]',
      },
    };

    const _init = (element) => {
      component.element = element;
      component.accountHeaderButton = component.element.querySelector(component.config.selectors.accountHeaderButton);
      component.accountHeaderButtonMobile = component.element.querySelector(component.config.selectors.accountHeaderButtonMobile);
      component.accountOverlay = document.querySelector(component.config.selectors.accountOverlay);
      component.basketHeaderButton = document.querySelector(component.config.selectors.basketHeaderButton);
      component.logoutButton = document.querySelector(component.config.selectors.logoutButton);
      component.openFlyoutPanel = document.querySelector(component.config.selectors.openFlyoutPanel);

      component.headerBreakpoint = 1200;
      component.windowWidthPrevious = component.getWindowWidth();
      component.mobileLayout = component.windowWidthPrevious < component.headerBreakpoint;

      // So that we can close one menu when we open the other
      app.subscribe('header/closeAccountResponsive', component.toggleAccountFlyoutMenuMobile);
      component.bind();

      return component;
    };

    const _bind = () => {
      if(component.accountHeaderButtonMobile) {
        component.accountHeaderButtonMobile.addEventListener('click', component.toggleAccountFlyoutMenuMobile);
      }

      const focusIn = (e) => {
        component.accountHeaderButton.setAttribute('data-focused', true);
        component.accountHeaderButton.setAttribute('aria-expanded', false);
        component.accountHeaderButton.addEventListener('click', (i) => component.toggleAccountFlyoutMenu(e, i));
      }

      component.accountHeaderButton.addEventListener('focusin', focusIn);
      document.addEventListener('focusin', (e) => component.toggleAccountFlyoutMenu(e));
      document.addEventListener('focusout', (e) => component.toggleAccountFlyoutMenu(e));

      component.accountHeaderButton.addEventListener('focusout', () => {
        component.accountHeaderButton.setAttribute('data-focused', false);
        component.toggleAccountFlyoutMenu();
        component.accountHeaderButton.removeEventListener('click', component.toggleAccountFlyoutMenu);
      });


      component.accountOverlay.addEventListener('click', component.toggleAccountFlyoutMenuMobile);
      $window.addEventListener('optimizedResize', component.resizeEvent, false);
      _throttle('resize', 'optimizedResize');

      if (document.querySelector(component.config.selectors.logoutButton)) {
        component.logoutButton.addEventListener('click', () => component.logOutEvent());
      }
    };

    const accountActiveClassList = [
      'responsiveAccountHeader_accountGroup',
      'responsiveAccountHeader',
    ];

    const allowDropdownClass = 'allow-dropdown';

    const _toggleAccountFlyoutMenu = (focusEvent, clickEvent) => {
      if (focusEvent) {
        const partOfDropdown = focusEvent.target ? accountActiveClassList.includes(focusEvent.target.parentNode.parentNode.className) : false;
        if (!partOfDropdown) {
          if (
            component.accountHeaderButton.getAttribute('aria-expanded') === 'true' ||
            (component.openFlyoutPanel && component.openFlyoutPanel.classList.contains(allowDropdownClass))) {
            component.accountHeaderButton.setAttribute('aria-expanded', false);
            component.accountHeaderButton.setAttribute('data-focused', false);
            component.openFlyoutPanel.classList.remove(allowDropdownClass);
          }

          return;
        }
      }

      const isNotMouseClick = clickEvent && clickEvent.pointerType !== 'mouse';
      if (isNotMouseClick) clickEvent.preventDefault();

      const expanded = component.accountHeaderButton.getAttribute('data-focused') && ![null, undefined].includes(clickEvent);

      component.accountHeaderButton.setAttribute('aria-expanded', `${expanded}`);
    if (!component.openFlyoutPanel) return;

      const hasAllowClass = component.openFlyoutPanel.classList.contains(allowDropdownClass);

      if (expanded && !hasAllowClass) {
        component.openFlyoutPanel.classList.add(allowDropdownClass);
        if (accessibilityAnnouncer != null) {
          accessibilityAnnouncer.announce('polite', component.accountHeaderButton.querySelector('.responsiveAccountHeader_openAccountPanelText').textContent + ' expanded');
        }
      }
    }


    const _toggleAccountFlyoutMenuMobile = () => {
      component.expanded = !(component.accountHeaderButtonMobile.getAttribute('aria-expanded') === 'true');

      if (component.basketHeaderButton.getAttribute('aria-expanded') === 'true') {
        app.publish('header/closeBasketResponsive');
      }

      component.accountHeaderButtonMobile.setAttribute('aria-expanded', component.expanded);

      if (component.expanded) {
        document.addEventListener('keydown', component.escapeListener);
        document.body.classList.add('popup-no-scroll');
      } else {
        document.removeEventListener('keydown', component.escapeListener);
        document.body.classList.remove('popup-no-scroll');

      }
    };

    const _escapeListener = event => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        component.toggleAccountFlyoutMenuMobile();
      }
    };

    const _getWindowWidth = () => {
      return $window.innerWidth;
    };

    const _resizeEvent = () => {
      component.windowWidthCurrent = component.getWindowWidth();

      if (component.mobileLayout && component.windowWidthCurrent > component.headerBreakpoint && component.expanded) {
        component.toggleAccountFlyoutMenuMobile();
      }

      component.mobileLayout = component.windowWidthCurrent < component.headerBreakpoint;
    };

    const _throttle = function (type, name, comp) {
      comp = comp || $window;
      let running = false;
      let func = function () {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function () {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    const _logOutEvent = ()  => {
      window.dataLayer.push({
        event: "elysiumEvent",
        eventData: {
          eventCategory: "logout",
        }
      })
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.toggleAccountFlyoutMenu = _toggleAccountFlyoutMenu;
    component.toggleAccountFlyoutMenuMobile = _toggleAccountFlyoutMenuMobile;
    component.getWindowWidth = _getWindowWidth;
    component.resizeEvent = _resizeEvent;
    component.escapeListener = _escapeListener;
    component.logOutEvent = _logOutEvent;

    return component;
  };

  return responsiveAccountHeader;
});
